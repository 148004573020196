.app {
  background-image: url(milky-way-2695569_1920.jpg);
  place-items: center;
  position: relative;
  height: 100vh;
  width: 400px;
  overflow: scroll;
}
.app::-webkit-scrollbar {
  display: none;
}

.app__tasks {
  position: relative;
  top: 20px;
  text-align: center;
  align-content: center;
  color: white;
  overflow: scroll;
  max-height: 80vh;
  bottom: 30vh;
  width: 100%;
}

.app__tasks::-webkit-scrollbar {
  display: none;
}

.app__loader {
  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
}
