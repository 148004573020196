.task {
  position: relative;
  height: 60px;
  background-color: rgb(1, 52, 204);
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.task p {
  position: absolute;
  width: fit-content;
  margin: 5px;
}
.task__close {
  position: absolute;
  right: 10px;
}

.fadeOut {
  opacity: 0;
  transition: width 1s 1s, height 1s 1s, opacity 1s;
}
.fadeIn {
  opacity: 1;
  transition: width 1s, height 1s, opacity 1s 1s;
}
