.account__overlay {
  z-index: 2;
  top: 0;
  opacity: 0.75;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #303030;
  transition: opacity 0.5s ease-in-out;
}
.account__signup {
  position: absolute;
  width: 100%;
  top: 100px;
  width: 80%;
  z-index: 3;
}
.account__signupFields {
  width: 90%;
  display: flex;
  flex-direction: column;
  height: 260px;
  padding: 20px;
  border-radius: 30px;
  background-color: rgb(1, 52, 204);
  margin: 10%;
}
.account__signup input {
  height: 50px;
  width: 80%;
  color: #303030;
  border: none;
  resize: none;
  font-size: 25px;
  font-weight: 400;
  overflow-y: scroll;
  border-radius: 30px;
  text-indent: 10px;
}

.account__signup input:focus {
  outline-width: 0;
}

.account__signup input::-webkit-input-placeholder {
  color: #a0a0a0;
  font-weight: 100;
  text-align: center;
  font-style: italic;
}

.account__signup input:focus {
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 113, 84, 0.1);
  -moz-box-shadow: 0 0 0 4px rgba(255, 113, 84, 0.1);
  box-shadow: 0 0 0 4px rgba(255, 113, 84, 0.1);
  border: solid 1px rgba(0, 190, 16, 0.6);
  color: #09213d;
}

.account__signup__input__error {
  position: relative;
  animation: shake 0.1s linear;
  animation-iteration-count: 3;
  border: solid 2px rgba(207, 37, 3, 0.6);
  background-color: rgb(255, 167, 167);
}

@keyframes shake {
  0% {
    left: -5px;
  }
  100% {
    right: -5px;
  }
}
