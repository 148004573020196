.control {
  z-index: 1;
  position: fixed;
  text-align: center;
  color: seashell;
  width: 400px;
  display: flex;
  justify-content: space-around;
  bottom: 1vh;
}

.control__button {
  color: seashell;
}
.control__addControl {
  text-align: center;
}
