.signedin {
}

.signedin__logout {
}
.signedin__info {
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  color: #eeeeee;
}
